import { Api } from './api'
import { StaffDto } from '@/models/staff-profile/classes/StaffCreate'

class StaffProfileApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async createStaff(staff: StaffDto): Promise<string> {
    const res = await Api.post<any>(`${this.baseURL}/v1/people/staff`, staff)
    return res._id
  }
}

export const StaffProfileAPI = new StaffProfileApi()
