import { PersonRoles } from '@/models/person/enums'

export class CanBeTeacherOf {
  product: string
  role: PersonRoles

  constructor (
    product: string,
    role: PersonRoles
  ) {
    this.product = product
    this.role = role
  }
}

export class Status {
  applied: boolean
  approved: boolean
  notNow: boolean
  unenroll: boolean
  fired: boolean
  resignation: boolean
  rejected: boolean

  constructor (
    applied: boolean,
    approved: boolean,
    notNow: boolean,
    unenroll: boolean,
    fired: boolean,
    resignation: boolean,
    rejected: boolean
  ) {
    this.applied = applied
    this.approved = approved
    this.notNow = notNow
    this.unenroll = unenroll
    this.fired = fired
    this.resignation = resignation
    this.rejected = rejected
  }
}

export class Roles {
  teacher: boolean
  assistant: boolean
  coordinator: boolean
  specialist: boolean
  influencer: boolean

  constructor (
    teacher: boolean,
    assistant: boolean,
    coordinator: boolean,
    specialist: boolean,
    influencer: boolean
  ) {
    this.teacher = teacher
    this.assistant = assistant
    this.coordinator = coordinator
    this.specialist = specialist
    this.influencer = influencer
  }
}

export class Profile {
  avatar: string
  firstName: string
  lastName: string
  dni: string
  dniType: string
  country: string
  phone: string

  constructor (
    avatar: string,
    firstName: string,
    lastName: string,
    dni: string,
    dniType: string,
    country: string,
    phone: string
  ) {
    this.avatar = avatar
    this.firstName = firstName
    this.lastName = lastName
    this.dni = dni
    this.dniType = dniType
    this.country = country
    this.phone = phone
  }
}

export class StaffDto {
  email: string
  profile: Profile
  interview: Status
  roles: Roles
  role: number
  canBeTeacherOf: CanBeTeacherOf[]

  constructor (
  ) {
    this.email = ''
    this.profile = { avatar: '', firstName: '', lastName: '', dni: '', country: '', phone: '', dniType: 'DNI' }
    this.interview = { applied: false, approved: false, notNow: false, unenroll: false, fired: false, resignation: false, rejected: false }
    this.roles = { teacher: true, assistant: false, coordinator: false, specialist: false, influencer: false }
    this.role = 3 // staff
    this.canBeTeacherOf = []
  }
}
