import { PersonRoles } from '../enums'
import { IdentityDocument } from '../interfaces/IdentityDocument'
import { Interview } from '../interfaces/Interview'
import { CanBeTeacherOf } from '../interfaces/CanBeTeacherOf'

export class Staff {
  firstname: string
  lastname: string
  email: string
  avatar: string
  rol: number
  roles: PersonRoles[]
  identityDocument: IdentityDocument
  phone: string
  country: string
  activeRole?: PersonRoles
  unenroll?: boolean
  interview?: Interview
  canBeTeacherOf?: CanBeTeacherOf[]

  constructor (
  ) {
    this.firstname = ''
    this.lastname = ''
    this.email = ''
    this.avatar = ''
    this.avatar = ''
    this.rol = 0
    this.roles = [PersonRoles.Teacher]
    this.identityDocument = { identity: null, identityType: 'dni' }
    this.phone = ''
    this.country = ''
    this.activeRole = PersonRoles.Teacher
    this.unenroll = false
    this.interview = { rejected: false, applied: false, approved: false, unenroll: false, notNow: false }
    this.canBeTeacherOf = []
  }
}
