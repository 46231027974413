import { Api } from './api'

class StoreAPI {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async uploadFileCloudinary(file: FormData): Promise<string> {
    const url = await Api.put<string>(
      `${this.baseURL}/v1/store/upload/cloudinary`,
      file
    )
    return url
  }
}

export const storeAPI = new StoreAPI()
