
import CommentBox from '@/components/commons/boxes/CommentBox/CommentBox.vue'
import CoursesMultipleSelector from '@/components/commons/selectors/CoursesMultipleSelector.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email, minLength } from '@vuelidate/validators'
import { defineComponent, onMounted, ref, computed, reactive } from 'vue'
import { StaffDto } from '@/models/staff-profile/classes/StaffCreate'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { StaffProfileAPI } from '@/api/staff-profile.api'
import { useToast } from 'primevue/usetoast'
import { Comment } from '@/models/comments'
import { useCommentBoxEventsHandler } from '@/hooks/useCommentBoxEventsHandler'
import { storeAPI } from '@/api/store.api'

export default defineComponent({
  components: {
    CommentBox,
    CoursesMultipleSelector
  },
  setup () {
    const StaffCreate = ref<StaffDto>(new StaffDto())
    const coursePlans = ref<CoursePlan[]>([])
    const modelCourses = ref<string[]>([])
    const loading = ref(true)
    const loadingImg = ref(false)
    const showComments = ref(false)
    const _idUser = ref<string>()
    const selectedRol = ref<string>('')
    const countriesStore = useCountriesStore()
    const countries = computed(() => countriesStore.countries)
    const inCountry = ref<string>(StaffCreate.value.profile.country as string)
    const inApplied = ref<boolean>(StaffCreate.value.interview?.applied as boolean)
    const inApprobed = ref<boolean>(StaffCreate.value.interview?.approved as boolean)
    const inNotNow = ref<boolean>(StaffCreate.value.interview?.notNow as boolean)
    const inTeacher = ref<boolean>(StaffCreate.value.roles?.teacher as boolean)
    const inTutor = ref<boolean>(StaffCreate.value.roles?.assistant as boolean)
    const inCoordinator = ref<boolean>(StaffCreate.value.roles?.coordinator as boolean)
    const toast = useToast()
    const comments = ref<Comment[]>([])
    const { handleSave: onCommentBoxSaveEvent } = useCommentBoxEventsHandler(comments)
    const state = reactive({
      firstName: '',
      lastName: '',
      dni: '',
      country: '',
      email: '',
      phone: ''
    })
    const rules = {
      firstName: { required },
      lastName: { required },
      dni: { required, numeric },
      country: { required },
      email: { required, email },
      phone: { required, minLengthValue: minLength(8), numeric }
    }
    const v$ = useVuelidate(rules, state)

    const setState = (selectedCheck: boolean, estado: string) => {
      if (StaffCreate.value.interview) {
        switch (estado) {
          case 'applied':
            inApplied.value = !selectedCheck
            StaffCreate.value.interview.applied = inApplied.value
            break
          case 'approved':
            inApprobed.value = !selectedCheck
            StaffCreate.value.interview.approved = inApprobed.value
            break
          case 'notNow':
            inNotNow.value = !selectedCheck
            StaffCreate.value.interview.notNow = inNotNow.value
            break
          default:
            break
        }
      }
    }

    const setRoles = (selectedCheck: boolean, estado: string) => {
      if (StaffCreate.value.roles) {
        switch (estado) {
          case 'tutor':
            inTutor.value = !selectedCheck
            StaffCreate.value.roles.assistant = inTutor.value
            break
          case 'coordinator':
            inCoordinator.value = !selectedCheck
            StaffCreate.value.roles.coordinator = inCoordinator.value
            break
          default:
            break
        }
      }
    }

    const saveStaff = async () => {
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()
      const { firstName, lastName, dni, country, email, phone } = v$.value
      if (isFormCorrect) {
        StaffCreate.value.profile.firstName = firstName.$model as string
        StaffCreate.value.profile.lastName = lastName.$model as string
        StaffCreate.value.profile.dni = dni.$model as string
        StaffCreate.value.profile.country = country.$model as string
        StaffCreate.value.email = email.$model as string
        StaffCreate.value.profile.phone = phone.$model as string

        await StaffProfileAPI.createStaff(StaffCreate.value).then(id => {
          toast.add({ severity: 'success', detail: 'El staff se creo correctamente.', life: 3500 })
          showComments.value = true
          _idUser.value = id
        }).catch(
          (err: unknown) => {
            toast.add({ severity: 'error', summary: 'Error al crear staff', detail: String(err), life: 3500 })
            showComments.value = false
          }
        )
      }
    }

    const setCanBeTeacherOf = (courses: string[]) => {
      modelCourses.value = courses
      courses.forEach(course => {
        StaffCreate.value.canBeTeacherOf.filter(function (cbto) {
          return cbto.product === course
        })
      })
    }

    const changeRolCanBeTeacherOf = (beTeacherOf: any) => {
      const course = StaffCreate.value.canBeTeacherOf.find(cbto => cbto.product === beTeacherOf.course)
      if (course) {
        const i = StaffCreate.value.canBeTeacherOf.indexOf(course)
        StaffCreate.value.canBeTeacherOf[i].role = beTeacherOf.rol
      } else {
        const teacherOf = {
          product: beTeacherOf.course,
          role: beTeacherOf.rol
        }
        StaffCreate.value.canBeTeacherOf?.push(teacherOf)
      }
    }

    const changeCountry = (selectedCountry: any) => {
      inCountry.value = selectedCountry.value
      StaffCreate.value.profile.country = inCountry.value
    }

    const onChangeFile = async (event: any) => {
      loadingImg.value = true
      const formData = new FormData()
      formData.append('file', event.target.files[0], event.target.files[0].name)
      await storeAPI.uploadFileCloudinary(formData).then(res => {
        toast.add({ severity: 'success', detail: 'El archivo se subio correctamente.', life: 3500 })
        StaffCreate.value.profile.avatar = res
      }).catch(
        (err: unknown) => {
          toast.add({ severity: 'error', summary: 'Error al subir el avatar', detail: String(err), life: 3500 })
        }
      ).finally(() => {
        loadingImg.value = false
      })
    }

    onMounted(async () => {
      try {
        loading.value = true
        coursePlans.value = await CoursePlanAPI.findAll()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })

    return {
      v$,
      loadingImg,
      StaffCreate,
      coursePlans,
      modelCourses,
      countries,
      inCountry,
      inTeacher,
      inTutor,
      inCoordinator,
      inApplied,
      inApprobed,
      inNotNow,
      showComments,
      comments,
      selectedRol,
      setState,
      setRoles,
      setCanBeTeacherOf,
      changeRolCanBeTeacherOf,
      saveStaff,
      onCommentBoxSaveEvent,
      changeCountry,
      onChangeFile
    }
  }
})
