
import { defineComponent, ref } from 'vue'
import CreateForm from '@/views/education/persons/person/components/CreateForm.vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import { Staff } from '@/models/person/classes/Staff'
export default defineComponent({
  components: {
    ModuleHeader,
    LoadingScreen,
    CreateForm
  },
  setup () {
    const isLoading = ref(false)
    const person = ref<Staff|null>(new Staff())

    return {
      person
    }
  }
})
